import './MainPage.styles.css';
import PlaySound from "../playSound/PlaySound";
import p1 from '../../assets/pictures/1.jpg'
import p2 from '../../assets/pictures/2.jpg'
import p3 from '../../assets/pictures/3.jpg'
import p4 from '../../assets/pictures/5.jpg'
import p5 from '../../assets/pictures/6.jpg'
import p6 from '../../assets/pictures/7.jpg'
import p7 from '../../assets/pictures/8.jpg'
import p8 from '../../assets/pictures/9.jpg'
import p9 from '../../assets/pictures/10.jpg'
import p10 from '../../assets/pictures/11.jpg'
import p11 from '../../assets/pictures/12.jpg'
import p12 from '../../assets/pictures/13.jpg'
import p13 from '../../assets/pictures/14.jpg'
import p14 from '../../assets/pictures/15.jpg'
import p15 from '../../assets/pictures/16.jpg'
import p16 from '../../assets/pictures/4.jpg'
import p17 from '../../assets/pictures/17.gif'
import p18 from '../../assets/pictures/18.gif'
import p19 from '../../assets/pictures/19.gif'
import p20 from '../../assets/pictures/20.gif'
import p21 from '../../assets/pictures/22.mp4'
import p23 from '../../assets/pictures/23.mp4'
import p24 from '../../assets/pictures/24.jpg'
import p25 from '../../assets/pictures/25.jpg'
import p26 from '../../assets/pictures/26.jpg'
import p27 from '../../assets/pictures/27.jpg'
import p28 from '../../assets/pictures/28.jpg'
import p29 from '../../assets/pictures/29.png'
import p30 from '../../assets/pictures/30.jpg'
import p31 from '../../assets/pictures/31.png'
import p33 from '../../assets/pictures/33.mp4'
import p34 from '../../assets/pictures/34.mp4'
import p35 from '../../assets/pictures/35.mp4'
import p36 from '../../assets/pictures/36.mp4'
import p37 from '../../assets/pictures/37.mp4'
import p38 from '../../assets/pictures/38.mp4'
import p39 from '../../assets/pictures/39.mp4'
import p40 from '../../assets/pictures/40.mp4'
import p41 from '../../assets/pictures/41.mp4'
import p42 from '../../assets/pictures/42.mp4'
import p43 from '../../assets/pictures/43.mp4'
import p44 from '../../assets/pictures/44.jpg'
import p45 from '../../assets/pictures/45.jpg'
import p46 from '../../assets/pictures/46.jpg'
import finale from '../../assets/pictures/finale.mp4'





function MainPage() {
  return (
    <div className="mainPage">
      <PlaySound/>
      <div className='imagesContainer'>
        <img className='picture' src={p1} alt=""/>
        <img className='picture' src={p2} alt=""/>
        <img className='picture' src={p3} alt=""/>
        <img className='picture' src={p4} alt=""/>
        <img className='picture' src={p5} alt=""/>
        <img className='picture' src={p6} alt=""/>
        <img className='picture' src={p7} alt=""/>
        <img className='picture' src={p8} alt=""/>
        <img className='picture' src={p9} alt=""/>
        <img className='picture' src={p10} alt=""/>
        <img className='picture' src={p11} alt=""/>
        <img className='picture' src={p12} alt=""/>
        <img className='picture' src={p13} alt=""/>
        <img className='picture' src={p14} alt=""/>
        <img className='picture' src={p15} alt=""/>
        <img className='picture' src={p16} alt=""/>
        <img className='picture' src={p17} alt=""/>
        <img className='picture' src={p18} alt=""/>
        <img className='picture' src={p19} alt=""/>
        <img className='picture' src={p20} alt=""/>
        <video className='picture' autoPlay muted loop>
          <source src={p21} type="video/mp4"/>
        </video>
        <video className='picture' autoPlay muted loop>
          <source src={p23} type="video/mp4"/>
        </video>
        <img className='picture' src={p24} alt=""/>
        <img className='picture' src={p25} alt=""/>
        <img className='picture' src={p26} alt=""/>
        <img className='picture' src={p27} alt=""/>
        <img className='picture' src={p28} alt=""/>
        <img className='picture' src={p29} alt=""/>
        <img className='picture' src={p30} alt=""/>
        <img className='picture' src={p31} alt=""/>
        <video className='picture' autoPlay muted loop>
          <source src={p33} type="video/mp4"/>
        </video>
        <video className='picture' autoPlay muted loop>
          <source src={p34} type="video/mp4"/>
        </video>
        <video className='picture' autoPlay muted loop>
          <source src={p35} type="video/mp4"/>
        </video>
        <video className='picture' autoPlay muted loop>
          <source src={p36} type="video/mp4"/>
        </video>
        <video className='picture' autoPlay muted loop>
          <source src={p37} type="video/mp4"/>
        </video>
        <video className='picture' autoPlay muted loop>
          <source src={p38} type="video/mp4"/>
        </video>
        <video className='picture' autoPlay muted loop>
          <source src={p39} type="video/mp4"/>
        </video>
        <video className='picture' autoPlay muted loop>
          <source src={p40} type="video/mp4"/>
        </video>
        <video className='picture' autoPlay muted loop>
          <source src={p41} type="video/mp4"/>
        </video>
        <video className='picture' autoPlay muted loop>
          <source src={p42} type="video/mp4"/>
        </video>
        <video className='picture' autoPlay muted loop>
          <source src={p43} type="video/mp4"/>
        </video>
        <img className='picture' src={p44} alt=""/>
        <img className='picture' src={p45} alt=""/>
        <img className='picture' src={p46} alt=""/>

      </div>
      <div className='finale'>
        <video className='finaleVideo' autoPlay muted loop>
          <source src={finale} type="video/mp4"/>
        </video>
      </div>

      <p className='finalMessage'>As long as the internet exists, this memories will too! Happy birthday, my dearest !</p>
    </div>

  );
}

export default MainPage;
