import '../mainPage/MainPage.styles.css';
import Sound from 'react-sound';
import Bruno from '../../assets/sounds/Bruno-Mars-Count-On-Me.mp3'
import {useState} from "react";
import './PlaySound.styles.css'


function PlaySound() {
  const handleSongPlaying = () => {
    // some animation with hearts
  }
  const handleSongLoading = () => {

  }

  const handleSongFinishedPlaying = () => {

  }
  const [isPlaying, setIsPlaying] = useState(false)
  return (
    <>
      <div className='button-55' onClick={() => setIsPlaying(!isPlaying)}>{isPlaying ? 'Stop' : 'Play'}</div>
      <Sound
        url={Bruno}
        playStatus={isPlaying ? Sound.status.PLAYING : Sound.status.STOPPED}
        playFromPosition={300}
        onLoading={handleSongLoading}
        onPlaying={handleSongPlaying}
        onFinishedPlaying={handleSongFinishedPlaying}
      />
    </>

  );
}

export default PlaySound;
