import cat from "../../assets/pictures/cat.png";
import './HomePage.styles.css'

function HomePage() {
  return (
      <div></div>
    // <div className='wrapper'>
    //   <p>Almost there. Read your note again</p>
    //   <img className='catPicture' src={cat} alt=""/>
    // </div>
  )
}

export default HomePage;
